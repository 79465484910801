import React from 'react'
import { graphql, PageProps } from 'gatsby'
import BlogDetail from '~/components/pages/BlogDetail'
import SEO from '~/utils/seo'

import { summarize } from '~/utils/seo'

export default function OnsenTimesArticle(props: PageProps<GatsbyTypes.OnsenTimesArticleQuery>) {
    const article = props.data.microcmsNews
    return (
        <>
            <SEO
                title={`${article.title} | ニュース | Onsen*`}
                description={summarize(article.contents)}
                url={props.location.href}
                image={article.thumbnail.url}
                robots={article.robots}
                type='article'
            />
            <BlogDetail {...props} />
        </>
    )
}

export const query = graphql`
    query OnsenTimesArticle($newsId: String!) {
        microcmsNews(newsId: {eq: $newsId}) {
            title
            publishedAt(formatString: "YYYY.MM.DD")
            contents
            isThumbnailShow
            thumbnail {
                url
            }
            robots
            postDate(formatString: "YYYY.MM.DD")
            keyword {
                id
                title
            }
        }
    }
`
